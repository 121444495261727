// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Get companyKeys by UID
 *
 * @param {string} id - UID companyKey in database
 */
export async function getCompanyKeyById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/company-key/${id}`
  })

  return result.data
}

/**
 * Update companyKeys in database
 *
 * @param {Object} data - companyKeys data
 */
export async function updateCompanyKeyById(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/company-key/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}
