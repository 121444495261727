// Constans
import { ADDONS } from '@/constants'
// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import FormButtons from '@/components/ui/FormButtons'
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import GoogleMapAutocomplete from '@/components/ui/GoogleMapAutocomplete'
import GoogleMapLoader from '@/components/ui/GoogleMapLoader'
import GoogleMapMarker from '@/components/ui/GoogleMapMarker'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import { getUrlPlace, getEveryPlacesByIds } from '@/services/place'
import { getCompanyKeyById, updateCompanyKeyById } from '@/services/companyKeys'
import { clonePlace } from '../../services/place'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { get } from 'lodash'
import { getEnvironmentVariable, timeout } from '@/utils'

export default {
  name: 'MultiPlacesClone',
  components: {
    VuetifyContentLoading,
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapAutocomplete,
    FormButtons,
    VuetifyToolBar,
    CardContainer,
    CardContainerElement
  },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        companyKeysId: null, // Solo para Schweppes
        name: null,
        phone1: null,
        place: null,
        placeSelected: null
      },
      formFieldsValidations: {
        companyKeysId: {
          required: 'Campo obligatorio',
          keyIsValid: 'La clave no existe o ya ha sido usada. Contacte con su responsable de área'
        },
        name: {
          required: 'Campo obligatorio'
        },
        phone1: {
          required: 'Campo obligatorio'
        },
        place: {
          required: 'Debe seleccionar una de las direcciones sugeridas'
        },
        placeSelected: {
          required: 'Campo obligatorio'
        }
      },
      // Others
      placeListData: [],
      placeMarker: null, // used to draw the marker in the map
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    ...mapGetters('authentication', ['userData']),
    /**
     * Estamos bajo la aplicación de Schweppes
     *
     * @return {Boolean}
     */
    isSchweppes() {
      return getEnvironmentVariable('VUE_APP_BRAND') === 'schweppes'
    },
    /**
     * Se setea el estado para los radio buttons
     *
     * @return {Array}
     */
    placeListOptions() {
      return this.placeListData.map((place) => {
        return { value: place.id, label: place.name }
      })
    }
  },
  watch: {
    'formFields.place'(value) {
      if (value) {
        // Redibujamos la marca en el mapa
        this.setMarkerInMap(value)
      }
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('authentication', ['updateUser']),
    ...mapActions('company', ['updateCompany']),
    /**
     * Show alert with error
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    // Se retorna a la pagina anterior
    handleCancelButton() {
      this.routerGoTo()
    },
    /**
     * Obtenemos todas los datos necesarios
     * para montar el componente desde un inicio
     */
    async getEveryNeededData() {
      try {
        this.processingRequest = true
        this.placeListData = await this.getPlaceListData()
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Set marker to show in map
     * @param {Object} place - place, address
     */
    setMarkerInMap(place) {
      this.placeMarker = {
        title: place.locality,
        label: place.locality,
        position: {
          lat: place.latitude,
          lng: place.longitude
        }
      }
      // Posicionamos el mapa en el centro de la búsqueda
      this.$refs.googleMapLoader.setCenterMap({
        lat: place.latitude,
        lng: place.longitude
      })
    },
    /**
     * Se obtiene todos los places y su data asociados
     * al usuario logado
     *
     * @return {Array}
     */
    async getPlaceListData() {
      // Se obtiene todos los places asociados al usuario autenticado
      const placesKeys = Object.keys(this.userData.places)

      return (await getEveryPlacesByIds(placesKeys)).map((place) => {
        const addOnConfigs = get(place, 'addOnConfigs', [])
        const configData = addOnConfigs.find((config) => config.id === ADDONS.place)
        return {
          ...place,
          name: configData.configFields.name || place.name
        }
      })
    },
    /**
     * Evento de formulario
     */
    async afterSubmit() {
      // Datos del establecimiento seleccionado
      const placeSelected = this.placeListData.find(
        (place) => place.id === this.formFields.placeSelected
      )

      // Generar URL unica
      const { url } = await getUrlPlace(this.formFields.name)
      // Datos a salvar del establecimiento
      const placeSelectedToSave = {
        brand: placeSelected.brand || null,
        companies: placeSelected.companies || null,
        users: { [this.userData.id]: true },
        url
      }
      // Datos a salvar de las configuraciones
      // del establecimiento
      const placeSelectedConfigs = get(placeSelected, 'addOnConfigs', [])
      const placeSelectedConfig = placeSelectedConfigs.find((config) => config.id === ADDONS.place)
      const placeSelectedConfigToSave = {
        place: {
          additionalLanguages: get(placeSelectedConfig, 'configFields.additionalLanguages', []),
          color: get(placeSelectedConfig, 'configFields.color', '#000'),
          cover: get(placeSelectedConfig, 'configFields.cover', null),
          currency: get(placeSelectedConfig, 'configFields.currency', {}),
          defaultLanguage: get(placeSelectedConfig, 'configFields.defaultLanguage', 'es'),
          logo: get(placeSelectedConfig, 'configFields.logo', null),
          name: this.formFields.name
        },
        contact: {
          phone1: this.formFields.phone1,
          place: this.formFields.place
        }
      }

      // Incluimos el campos "companyKeysId"
      if (this.isSchweppes) {
        placeSelectedToSave.companyKeysId = this.formFields.companyKeysId
      }

      // Se crea un place nuevo, el siguiente paso de la clonación
      // creación de menús, categorías y productos se realizará en
      // Back para aligerar el proceso en el FRONT
      const placeCreated = await clonePlace(
        placeSelected.id,
        placeSelectedToSave,
        placeSelectedConfigToSave
      )

      // Marcamos la "companyKeysId" como usada
      if (this.isSchweppes) {
        await updateCompanyKeyById({
          enabled: false,
          id: this.formFields.companyKeysId
        })
      }

      // Se asocia el nuevo establecimiento al usuario
      await this.updateUser({
        id: this.userData.id,
        places: { ...this.userData.places, [placeCreated.id]: true }
      })

      // Se asocia el nuevo establecimiento a la compañía
      await this.updateCompany({
        id: this.companyData.id,
        places: { ...this.companyData.places, [placeCreated.id]: true }
      })

      // Paramos un instante
      await timeout(1000)

      // Se le muestra al usuario que todo ha salido correctamente
      this.modifyAppAlert({
        text: 'Proceso de clonación finalizado correctamente',
        visible: true
      })

      // Se redirije a la lista de establecimientos
      this.routerPushTo({ name: 'multiPlacesList' })
    }
  },
  // Validations with Vuelidate
  validations() {
    const rules = {
      formFields: {
        name: { required },
        phone1: { required },
        place: { required },
        placeSelected: { required }
      }
    }

    // Si este "addons" esta siendo usado
    // por un usuario de "schweppes" deberá
    // indicar el "companyKeysId"
    if (this.isSchweppes) {
      rules.formFields.companyKeysId = {
        required,
        keyIsValid: async (value) => {
          if (!value || value.length < 5) return false
          const companyKeysData = await getCompanyKeyById(value)
          if (!companyKeysData) return false
          return true
        }
      }
    }

    return rules
  }
}
