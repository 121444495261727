// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Termina de completar el proceso de clonación
 * de un establecimiento en el Back
 *
 * @param {String} id - Identificador del establecimiento a clonar (origen)
 * @param {Object} data - Datos del establecimiento a clonar (origen)
 * @param {Object} config - Configuraciones del establecimiento a clonar (origen)
 * @return {Object} - Identificar del establecimiento creado (destino)
 */
export async function clonePlace(id, data, config) {
  const result = await doRequest({
    method: 'post',
    url: `v1/place/clone/${id}`,
    data: {
      data,
      config
    }
  })

  return result.data
}
